import { checkNested } from 'MODULES/checkNested';
import { notify } from 'reapop';
import { NOTIFICATION_OK } from 'CONFIG';
import forEach from 'lodash/forEach';


export const notification = payload => (dispatch) => {
    forEach(checkNested(payload, 'notification', []), (n) => {
        dispatch(notify(n.message, NOTIFICATION_OK));
    });
};
