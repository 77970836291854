import { lazy } from 'react';

export const lazyRetry = componentImport => new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
        Object.getOwnPropertyDescriptor(window.location, 'lazyError')
            ? window.localStorage.getItem('lazyError')
            : false
    );

    componentImport().then((component) => {
        window.localStorage.setItem('lazyError', 'false');
        resolve(component);
    }).catch((error) => {
        if (!hasRefreshed) {
            window.localStorage.setItem('lazyError', 'true');
            return window.location.reload();
        }
        reject(error);
    });
});

export const loadComponent = componentImport => lazy(componentImport);
