import React from 'react';
import { HOST } from 'CONFIG';
import { Link } from 'react-router-dom';
import MainLogo from 'IMAGES/inlineSVG/pp_logo.svg';
import { PP_URLS } from 'CONFIG/PP_URLS';

const Logo = ({ linkClassName }) => (
    <div className="header-logo-wrapper" data-test="toolbar-logo-container">
        <div className="align-middle">
            <Link className={linkClassName} to={PP_URLS.HOME_LANG()} aria-label={HOST}>
                <MainLogo className="title-image" data-test="toolbar-logo" />
            </Link>
        </div>
    </div>
);

export default Logo;
