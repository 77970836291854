import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import values from 'lodash/values';
import {
    ADD_USER_LOT,
    AUTH_TOKENS,
    CHANGE_COMPANY_USER_DATA,
    CHECK_USER_COOKIE,
    CHECK_VERIFIED_MAIL,
    CLEAR_USER_STATE,
    CREATE_COMPANY_PERMISSIONS,
    DELETE_AVATAR,
    DELETE_COMPANY_SOCIAL_ACCOUNT,
    DELETE_USER_COMPANY_INVITE,
    DELETE_USER_COMPANY_MEMBER,
    DELETE_USER_CONTACT,
    DELETE_USER_SOCIAL_ACCOUNT,
    FLUSH_USER_CATEGORIES,
    GET_ALL_FEEDS,
    GET_COMPANIES_AVATAR,
    GET_COMPANY_SOCIAL_CONTACTS,
    GET_FEED,
    GET_INVITATION_DATA,
    GET_LOT_STATISTIC,
    GET_MY_LOTS_COUNT, MY_WALLET_DATA,
    GET_SOCIAL_CONTACTS,
    GET_USER_AVATAR,
    GET_USER_CATEGORIES,
    GET_USER_COMPANY,
    GET_USER_COMPANY_INVITES_PERMISSIONS,
    GET_USER_COMPANY_MEMBERS,
    GET_USER_COMPANY_MEMBERS_PERMISSIONS,
    GET_USER_COMPANY_PERMISSIONS,
    GET_USER_CONTACTS,
    GET_USER_DATA,
    GET_USER_OFFERS,
    GET_USER_PREFERENCES,
    LOGIN,
    LOGOUT,
    PASSWORD_RECOVERY_DATA,
    PASSWORD_RECOVERY_HASH,
    PASSWORD_RECOVERY_NEW,
    REMOVE_USER_LOT,
    REQUEST_NEW_USER_PASSWORD,
    RESEND_USER_COMPANY_INVITE,
    RESEND_USER_CONTACT,
    RESET_USER_STATE,
    SAVE_COMPANY_SOCIAL_CONTACT,
    SAVE_USER_SOCIAL_CONTACT,
    SAVED_USER_CONTACT,
    SIGN_UP,
    UPDATE_PASSWORD_PERMISSIONS,
    UPDATE_USER_AVATAR,
    UPDATE_USER_NAME,
    UPLOAD_FEED,
    USER_ACTIVE_PACKAGES,
    USER_COMPANY_INVITES,
    USER_COMPANY_INVITES_PERMISSIONS,
    USER_INVOICES,
    VERIFY_USER_CONTACT,
    GET_USER_REFERRAL,
    INCREMENT_USER_LOT, PATCH_USER_PREFERENCE, MY_WALLET_LOG_DATA, PREFERED_CONTACT_UPDATE
} from 'ACTIONS';
import { mapArrayToObjectWithProp } from 'MODULES';
import { CONTACT_STATUS } from 'CONST';
import { checkNested } from 'MODULES/checkNested';
import { setIn } from 'MODULES/setIn';

const INITIAL_STATE = {
    signInStatus: {
        cookie: false
    },
    userData: null,
    userCompany: {
        createPermissions: true,
        members: [],
        membersPermissions: {},
        invites: [],
        invitesPermissions: false,
        permissions: null,
        invitation: false
    },
    signUpStatus: null,
    offers: null,
    haveUnverifiedMail: false,
    password: {
        permissions: false,
        requested: {},
        recoveryNew: null
    },
    invoices: {},
    activePackages: null,
    userCategories: null,
    userCategoriesCount: null,
    userLots: null,
    contacts: {
        verified: {},
        pending: {},
        all: {}
    },
    socialContacts: [],
    companySocialContacts: [],
    lotStatistic: {},
    authTokens: null,
    preferences: null,
    feeds: {},
    uploadedFeed: null,
    wallet: null,
    logs: {
        count: 0
    }
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_FEED:
            return {
                ...state,
                feeds: { ...state.feeds, [action.payload.content.id]: action.payload.content }
            };
        case GET_ALL_FEEDS:
            return {
                ...state,
                feeds: action.payload
            };
        case UPLOAD_FEED:
            return {
                ...state,
                uploadedFeed: { ...state.uploadedFeed, id: action.payload.content.id, apiKey: checkNested(action.payload.content, 'apiKey') }
            };
        case AUTH_TOKENS:
            return {
                ...state,
                authTokens: action.payload.body || null
            };
        case LOGIN:
        case LOGOUT:
            return {
                ...state,
                signInStatus: (action.payload) ? { ...action.payload, cookie: action.payload.authenticated } : INITIAL_STATE.signInStatus
            };
        case CHECK_USER_COOKIE:
            return {
                ...state,
                signInStatus: { ...state.signInStatus, cookie: action.payload }
            };
        case GET_USER_DATA:
            return { ...state, userData: action.payload };
        case GET_USER_REFERRAL:
            return { ...state, referral: action.payload };
        case GET_USER_CONTACTS:
            return { ...state, contacts: action.payload };
        case SAVED_USER_CONTACT:
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    all: {
                        ...state.contacts.all,
                        [action.payload.id]: action.payload
                    },
                    pending: {
                        ...state.contacts.pending,
                        [action.payload.contactType]: {
                            ...checkNested(state.contacts.pending, action.payload.contactType, { }),
                            [action.payload.id]: action.payload
                        }
                    }
                }
            };
        case PREFERED_CONTACT_UPDATE:
            const prevPreferred = values(state.contacts.preferred[action.payload.contactType]);

            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    verified: {
                        ...state.contacts.verified,
                        [action.payload.contactType]: !isEmpty(prevPreferred) ? {
                            ...state.contacts.verified[action.payload.contactType],
                            [prevPreferred[0].id]: { ...state.contacts.verified[action.payload.contactType][prevPreferred[0].id], preferred: false },
                            [action.payload.id]: { ...state.contacts.verified[action.payload.contactType][action.payload.id], preferred: true }
                        } : {
                            ...state.contacts.verified[action.payload.contactType],
                            [action.payload.id]: { ...state.contacts.verified[action.payload.contactType][action.payload.id], preferred: true }
                        }
                    },
                    preferred: {
                        ...state.contacts.preferred,
                        [action.payload.contactType]: {
                            [action.payload.id]: action.payload
                        }
                    }
                }
            };
        case RESEND_USER_CONTACT:
            return {
                ...state, contacts: setIn(`pending.${action.userType}.${action.id}.verification.dateSent`, action.now, state.contacts)
            };
        case VERIFY_USER_CONTACT:
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    all: {
                        ...state.contacts.all,
                        [action.id]: {
                            ...state.contacts.all[action.id],
                            verified: CONTACT_STATUS.verified
                        }
                    },
                    verified: {
                        ...state.contacts.verified,
                        [action.contactType]: {
                            ...state.contacts.verified[action.contactType],
                            [action.id]:  { ...state.contacts.all[action.id], verified: CONTACT_STATUS.verified }
                        }
                    },
                    pending: {
                        ...state.contacts.pending,
                        [action.contactType]: omit(state.contacts.pending[action.contactType], action.id)
                    }
                }
            };
        case DELETE_USER_CONTACT:
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    [action.contactStatus]: omit(state.contacts[action.contactStatus], [action.contactType]),
                    all: omit(state.contacts.all, [action.id])
                }
            };
        case UPDATE_USER_NAME:
            return { ...state, userData: action.payload };
        case GET_USER_COMPANY:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    company: action.payload
                }
            };
        case CREATE_COMPANY_PERMISSIONS:
            return {
                ...state,
                userCompany: { ...state.userCompany, createPermissions: action.payload }
            };
        case GET_USER_COMPANY_PERMISSIONS:
            return {
                ...state,
                userCompany: { ...state.userCompany, permissions: action.companyPermissions }
            };
        case GET_USER_COMPANY_MEMBERS:
            return {
                ...state,
                userCompany: { ...state.userCompany, members: action.payload.data }
            };
        case CHANGE_COMPANY_USER_DATA:
            return {
                ...state,
                userCompany: {
                    ...state.userCompany,
                    members: state.userCompany.members.map(m => (m.id === action.user ? {
                        ...m,
                        ...action.data
                    } : m))
                }
            };
        case GET_USER_COMPANY_MEMBERS_PERMISSIONS:
            return {
                ...state,
                userCompany: { ...state.userCompany, membersPermissions: action.permissions }
            };
        case USER_COMPANY_INVITES_PERMISSIONS:
            return {
                ...state,
                userCompany: { ...state.userCompany, invitePermission: action.permissions }
            };
        case UPDATE_USER_AVATAR:
            return {
                ...state,
                userData: { ...state.userData, avatar: action.payload }
            };
        case DELETE_USER_COMPANY_MEMBER:
            return {
                ...state,
                userCompany: { ...state.userCompany, members: state.userCompany.members.filter(m => m.id !== action.id) }
            };
        case USER_COMPANY_INVITES:
            return {
                ...state,
                userCompany: { ...state.userCompany, invites: action.payload.data }
            };
        case GET_USER_COMPANY_INVITES_PERMISSIONS:
            return {
                ...state,
                userCompany: { ...state.userCompany, invitesPermissions: action.companyInvitesPermissions }
            };
        case RESEND_USER_COMPANY_INVITE:
            return {
                ...state,
                userCompany: {
                    ...state.userCompany,
                    invites: state.userCompany.invites.map((invite) => {
                        if (invite.id === action.id) {
                            return {
                                ...invite,
                                dateSent: action.now
                            };
                        }

                        return invite;
                    })
                }
            };
        case DELETE_USER_COMPANY_INVITE:
            return {
                ...state,
                userCompany: { ...state.userCompany, invites: state.userCompany.invites.filter(i => i.id !== action.id) }
            };
        case SIGN_UP:
            return { ...state, signUpStatus: action.payload };
        case GET_INVITATION_DATA:
            return {
                ...state,
                userCompany: { ...state.userCompany, invitation: action.payload }
            };
        case GET_USER_AVATAR:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    avatar: action.payload
                }
            };
        case GET_COMPANIES_AVATAR:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    company: {
                        ...state.userData.company,
                        avatar: action.payload
                    }
                }
            };
        case PASSWORD_RECOVERY_DATA:
            return {
                ...state,
                passwordRecoveryData: action.payload
            };
        case PASSWORD_RECOVERY_HASH:
            return {
                ...state,
                passwordRecoveryHash: action.payload
            };
        case PASSWORD_RECOVERY_NEW:
            return {
                ...state,
                password: { ...state.password, recoveryNew: action.payload }
            };
        case DELETE_AVATAR:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    avatar: null,
                    company: {
                        ...state.userData.company,
                        avatar: null
                    }
                }
            };
        case GET_USER_OFFERS:
            return { ...state, offers: mapArrayToObjectWithProp(action.payload.data, 'ad') };
        case CHECK_VERIFIED_MAIL:
            return { ...state, haveUnverifiedMail: action.payload };
        case UPDATE_PASSWORD_PERMISSIONS:
            return {
                ...state,
                password: { ...state.password, permissions: action.payload }
            };
        case REQUEST_NEW_USER_PASSWORD:
            return {
                ...state,
                password: {
                    ...state.password,
                    requested: {
                        time: action.time,
                        status: action.status,
                        text: action.text
                    }
                }
            };
        case USER_ACTIVE_PACKAGES:
            return { ...state, activePackages: action.payload };
        case GET_USER_CATEGORIES:
            return { ...state, userCategories: action.payload.data, userCategoriesCount: action.payload.count };
        case FLUSH_USER_CATEGORIES:
            return { ...state, userCategories: INITIAL_STATE.userCategories, userCategoriesCount: INITIAL_STATE.userCategoriesCount };
        case USER_INVOICES:
            return { ...state, invoices: { ...state.invoices, [action.key]: action.payload.data } };
        case CLEAR_USER_STATE:
            return INITIAL_STATE;
        case GET_MY_LOTS_COUNT:
            return { ...state, userLots: action.payload };
        case ADD_USER_LOT:
            return { ...state, userLots: state.userLots + 1 };
        case REMOVE_USER_LOT:
            return { ...state, userLots: state.userLots - 1 };
        case INCREMENT_USER_LOT:
            return { ...state, userLots: state.userLots + action.amount };
        case GET_LOT_STATISTIC:
            return {
                ...state,
                lotStatistic: {
                    ...state.lotStatistic,
                    [action.lot]: {
                        ...state.lotStatistic[action.lot],
                        [action.period]: action.payload.data
                    },
                    totalVisits: action.totalVisits
                }
            };
        case GET_USER_PREFERENCES:
            return { ...state, preferences: keyBy(action.payload, preference => preference.prefKind) };
        case PATCH_USER_PREFERENCE:
            return { ...state, preferences: { ...state.preferences, [action.payload.prefKind]: action.payload } };
        case GET_SOCIAL_CONTACTS:
            return {
                ...state,
                socialContacts: {
                    ...state.socialContacts,
                    ...action.payload.data
                }
            };
        case GET_COMPANY_SOCIAL_CONTACTS:
            return {
                ...state,
                companySocialContacts: {
                    ...state.companySocialContacts,
                    ...action.payload.data
                }
            };
        case SAVE_USER_SOCIAL_CONTACT:
            return {
                ...state,
                socialContacts: {
                    ...state.socialContacts,
                    [action.payload.id]: action.payload
                }
            };
        case DELETE_USER_SOCIAL_ACCOUNT:
            return {
                ...state,
                socialContacts: omitBy(state.socialContacts, { id: action.id })
            };
        case SAVE_COMPANY_SOCIAL_CONTACT:
            return {
                ...state,
                companySocialContacts: {
                    ...state.companySocialContacts,
                    [action.payload.id]: action.payload
                }
            };
        case DELETE_COMPANY_SOCIAL_ACCOUNT:
            return {
                ...state,
                companySocialContacts: omitBy(state.companySocialContacts, { id: action.id })
            };
        case MY_WALLET_DATA:
            return {
                ...state,
                wallet: action.payload
            };
        case MY_WALLET_LOG_DATA:
            return {
                ...state,
                logs: {
                    ...state.logs,
                    count: action.payload.count,
                    [action.currentPage]: action.payload.data
                }
            };
        case RESET_USER_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
