import Cookies from 'js-cookie';
import { DEFAULT_COOKIE_EXPIRE } from 'CONFIG';


class Storage {
    static set(name, param, isLocalStorage, expires = DEFAULT_COOKIE_EXPIRE) {
        const { localStorage } = window;
        if (isLocalStorage && localStorage) {
            return localStorage.setItem(name, param);
        }
        return Cookies.set(name, param, { expires, path: '/' });
    }

    static get(name, parse = false, isLocalStorage) {
        const { localStorage } = window;
        if (isLocalStorage && localStorage) {
            if (parse) {
                return JSON.parse(localStorage.getItem(name));
            }
            return localStorage.getItem(name);
        }
        if (parse) {
            try {
                return JSON.parse(Cookies.get(name, { path: '/' }));
            } catch (e) {
                return {};
            }
        }
        return Cookies.get(name, { path: '/' });
    }

    static remove(name, isLocalStorage) {
        const { localStorage } = window;
        if (isLocalStorage && localStorage) {
            return localStorage.removeItem(name);
        }
        return Cookies.remove(name, { path: '/' });
    }
}


export default Storage;
