import { checkNested } from 'MODULES/checkNested';
import { notify } from 'reapop';
import STATIC_TRANSLATIONS from 'MODULES/translations/static_translate';
import { NOTIFICATION_ERROR, NOTIFICATION_POSITION } from 'CONFIG';
import { TIME_TWENTY_SECONDS } from 'CONST';
import { createNotificationmessage } from 'MODULES/notification';
import forEach from 'lodash/forEach';


export const error = (data, i18n) => (dispatch) => {
    forEach(checkNested(data, 'error', []), (n) => {
        if (i18n) {
            // dispatch(notify(STATIC_TRANSLATIONS(n.message, i18n), NOTIFICATION_ERROR));
            dispatch(notify({
                message: STATIC_TRANSLATIONS(n.message, i18n),
                status: NOTIFICATION_ERROR,
                position: NOTIFICATION_POSITION,
                dismissAfter: TIME_TWENTY_SECONDS
            }));
        } else {
            // dispatch(notify(createNotificationmessage(n.message, n.values), NOTIFICATION_ERROR));
            dispatch(notify({
                message: createNotificationmessage(n.message, n.values),
                status: NOTIFICATION_ERROR,
                position: NOTIFICATION_POSITION,
                dismissAfter: TIME_TWENTY_SECONDS
            }));
        }
    });
};
