import { combineReducers } from 'redux';
import form from 'redux-form/es/reducer';
import { reducer as notificationsReducer } from 'reapop';
import hints from 'REDUCERS/hints_reducer';
import home from 'REDUCERS/home_reducer';
import feedReducer from 'REDUCERS/feed_reducer';
import xmlDocReducer from 'REDUCERS/xml_doc_reducer';
import formDataReducer from 'REDUCERS/form_data_reducer';
import BookmarksReducer from './bookmarks_reducer';
import CategoriesReducer from './categories_reducer';
import CompanyReducer from './company_reducer';
import CreatePpReducer from './create_classified_reducer';
import FiltersReducer from './filters_reducer';
import FilterSubscriptionsReducer from './filter_subscriptions_reducer';
import GeneralReducer from './general_reducer';
import MessagingReducer from './messaging_reducer';
import UserReducer from './user_reducer';
import SearchReducer from './search_reducer';
import uiReducer from './ui_reducer';
import routeCache from './route_cache_reducer';
import PaymentReducer from './payments_reducer';

import PpListReducer from './pp_list_reducer';
import apiReducer from './api';

const rootReducer = combineReducers({
    bookmarks: BookmarksReducer,
    categories: CategoriesReducer,
    company: CompanyReducer,
    home,
    createClassified: CreatePpReducer,
    feeds: feedReducer,
    filters: FiltersReducer,
    filterSubscriptions: FilterSubscriptionsReducer,
    form,
    formData: formDataReducer,
    general: GeneralReducer,
    xmlDoc: xmlDocReducer,
    messaging: MessagingReducer,
    user: UserReducer,
    search: SearchReducer,
    ui: uiReducer,
    routeCache,
    payments: PaymentReducer,
    ppList: PpListReducer,
    notifications: notificationsReducer(),
    hints,
    api: apiReducer
});

export default rootReducer;
