import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { logger } from 'redux-logger/src';
import reducers from './reducers';
import apiMiddleware from './midlewares/redux-thunk-axios';

const process = { env: __DOTENV__ || window.env };

export const history = createBrowserHistory();

const middlewares = [apiMiddleware];

if (process.env.NODE_ENV === 'development' && process.env.REDUX_LOGGER === 'enabled') {
    middlewares.push(logger);
}

export default function configureStore(preloadedState) {
    const store = createStore(
        reducers, // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                ...middlewares
            ),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : args => args
        )
    );

    return store;
}

