/* Project URLs */
import Storage from 'HOC/storage';

const language = () => Storage.get('language') || __DEFAULT_LANG__;



export const PP_URLS = {
    ABOUT: (page, lang = language()) => (`/${lang}/about/${page}`),
    CONTACTS: (lang = language()) => (`/${lang}/contacts`),
    COOPERATIVE: (lang = language()) => (`/${lang}/cooperative`),
    DIGEST: (lang, id) => `/${lang}/digest/${id}`,
    HOME: '/',
    HOME_LANG: (lang = language()) => (`/${lang}`),
    PP: (id, category, lang = language()) => (`/${lang}/${category}/!${id}`),
    SIGNUP: { hash: 'signup' },
    SIGNIN: { hash: 'signin' },
    Loan: (lang = language()) => (`/${lang}/loan`),
    Leasing: (lang = language()) => (`/${lang}/leasing`),
    MAPS: { hash: 'maps' },
    RECOVERY: { hash: 'recovery' },
    EMPTY: '/empty/page',
    INFO: (lang = language()) => (`/${lang}/about/info/page`),
    USER_CLASSIFIEDS: (user = ':user', lang = language()) => (`/${lang}/user/${user}`),
    USER_DATA_POLICY: (lang = language()) => (`/${lang}/about/user-data-policy`),
    RULES: (lang = language()) => (`/${lang}/about/info/rules`),
    COOKIES: (lang = language()) => (`/${lang}/about/info/cookies`),
    BOOKMARKS: '/my/bookmarks',
    ITEMS: '/my/items',
    FEED: '/my/feed',
    COMPANY_FEED: '/my/company_feed',
    REFERRAL: (user, hash, lang = language()) => (`/${lang}/referral/${user}/${hash}`),
    COMPANY: '/my/company',
    COMPANY_PUBLIC: (id, lang = language()) => (`/${lang}/company/${id}`),
    CREATE: '/my/create',
    EDIT: id => (`/my/edit/${id}`),
    NOTICE: (lang = language()) => (`/${lang}/about/notice`),
    PUBLISH: id => (`/my/publish/${id}`),
    SUBSCRIPTION_PLANS: (lang = language()) => (`/${lang}/subscription-plans`),
    SUBSCRIPTION_STEP: step => (`/my/subscription/${step}`),
    SUBSCRIPTION_STEP_ITEM: (step, item) => (`/my/subscription/${step}/${item}`),
    SUBSCRIPTION_STEP_PLAN: (step, plan) => (`/my/subscription/${step}/${plan}/plan`),
    STYLE_PAGE: '/style/page',
    STYLE_LOGO: '/style/logos',
    SEARCH: (lang = language()) => (`/${lang}/search`),
    SEARCH_QUERY: (query, lang = language()) => (`/${lang}/search/${query}`),
    PAYMENT: (lang = language()) => (`/${lang}/service/payment`),
    INVOICE: id => (`invoice/${id}`),
    INVOICE_PDF: id => (`invoice/${id}/pdf`),
    PAYMENT_STATUS: '/payment/status',
    PAYMENT_OK: '/my/subscription/ok',
    PAYMENT_FAIL: '/my/subscription/canc',
    FILTERS: '/my/filters',
    FILTERS_BY_HASH: hash => (`/my/filters/${hash}`),
    PROFILE: '/my/profile',
    MYALL: '/my/*',
    MY_PARSE: '/my/parse',
    IMPERSONATE: lang => `/${lang}/user-data`,
    LANDING: (lang, category) => `/${lang}/landing/${category}`,
    LANDING_REGION: (lang, category, region) => `/${lang}/landing/${category}/!${region}`,
    PROFILE_USER: '/my/profile#user',
    PROFILE_COMPANY: { pathname: '/my/profile', hash: 'companyData' },
    SOCIAL: '/my/social',
    MESSAGES: '/my/messages',
    POPULAR: 'lots/popular',
    MESSAGES_BY_HASH: hash => (`/my/messages/${hash}`),
    XML_DOCS: (lang = language()) => (`/${lang}/xml-docs`),
    CATEGORY: (category, lang = language()) => (`/${lang}/${category}`),
    PICTURE_UPLOAD_QR: (hash, lot, lang = language()) => (`/${lang}/qr/${lot}/${hash}`),
    MODAL_MAP: (category = ':category', lang = language(), callback = ':callback') => (`/${lang}/modal-map/${callback}/${category}`),
    FACEBOOK: 'https://www.facebook.com/percunpardod?utm_source=button&utm_medium=pp.lv&utm_campaign=social_button&utm_content=facebook',
    INSTAGRAM: 'https://www.instagram.com/percunpardod/?utm_source=button&utm_medium=pp.lv&utm_campaign=social_button&utm_content=instagram',
    INBOX: 'https://inbox.lv',
    WOOCOMMERCE: 'https://wordpress.org/plugins/pp-lv-feed-for-woocommerce/',
    OPENCART: 'https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=45234&filter_search=pp.lv&filter_download_id=61',
    PRESTASHOP: 'https://plugins.pp.lv/prestashop/'
};
