export const SORT_OPTIONS = {
    latestPersonalDESC: {
        name: 'latestPersonalDESC',
        title: i18n => (i18n.__('Latest personal')),
        urlParam: { orderColumn: 'orderDate', orderDirection: 'DESC' }
    },
    priceDESC: {
        name: 'priceDESC',
        title: i18n => (i18n.__('The most expensive first')),
        urlParam: { orderColumn: 'price', orderDirection: 'DESC' }
    },
    priceASC: {
        name: 'priceASC',
        title: i18n => (i18n.__('Cheapest first')),
        urlParam: { orderColumn: 'price', orderDirection: 'ASC' }
    },
    publishDateDESC: {
        name: 'publishDateDESC',
        title: i18n => (i18n.__('Latest published')),
        urlParam: { orderColumn: 'publishDate', orderDirection: 'DESC' }
    },
    bestMatch: {
        name: 'bestMatch',
        title: i18n => (i18n.__('Best match')),
        urlParam: {}
    },
    publishDateASC: {
        name: 'publishDateASC',
        title: i18n => (i18n.__('Oldest published')),
        urlParam: { orderColumn: 'publishDate', orderDirection: 'ASC' }
    },
    createDateDESC: {
        name: 'createDateDESC',
        title: i18n => (i18n.__('Latest created')),
        urlParam: { orderColumn: 'createDate', orderDirection: 'DESC' }
    },
    createDateASC: {
        name: 'createDateASC',
        title: i18n => (i18n.__('Oldest created')),
        urlParam: { orderColumn: 'createDate', orderDirection: 'ASC' }
    },
    createDateDESCBookmarks: {
        name: 'createDateDESCBookmarks',
        title: i18n => (i18n.__('Latest added')),
        urlParam: { orderColumn: 'createDate', orderDirection: 'DESC' }
    },
    createDateASCBookmarks: {
        name: 'createDateASCBookmarks',
        title: i18n => (i18n.__('Oldest added')),
        urlParam: { orderColumn: 'createDate', orderDirection: 'ASC' }
    },
    endDateDESC: {
        name: 'endDateDESC',
        title: i18n => (i18n.__('Closest end date')),
        urlParam: { orderColumn: 'endDate', orderDirection: 'DESC' }
    },
    endDateASC: {
        name: 'endDateASC',
        title: i18n => (i18n.__('Most distant end date')),
        urlParam: { orderColumn: 'endDate', orderDirection: 'ASC' }
    },
    viewsDESC: {
        name: 'viewsDESC',
        title: i18n => (i18n.__('Popular first')),
        urlParam: { orderColumn: 'views', orderDirection: 'DESC' }
    },
    viewsASC: {
        name: 'viewsASC',
        title: i18n => (i18n.__('Least popular first')),
        urlParam: { orderColumn: 'views', orderDirection: 'ASC' }
    },
    scoreDESC: {
        name: 'scoreDESC',
        title: i18n => (i18n.__('Recommended')),
        urlParam: { orderColumn: 'score', orderDirection: 'DESC' }
    },
    '20ASC': {
        name: '20ASC',
        title: i18n => (i18n.__('Date of manufacture Asc')),
        urlParam: { orderColumn: 'filter20', orderDirection: 'ASC' }
    },
    '20DESC': {
        name: '20DESC',
        title: i18n => (i18n.__('Date of manufacture Desc')),
        urlParam: { orderColumn: 'filter20', orderDirection: 'DESC' }
    },
    '22ASC': {
        name: '22ASC',
        title: i18n => (i18n.__('Engine volume Asc')),
        urlParam: { orderColumn: 'filter22', orderDirection: 'ASC' }
    },
    '22DESC': {
        name: '22DESC',
        title: i18n => (i18n.__('Engine volume Desc')),
        urlParam: { orderColumn: 'filter22', orderDirection: 'DESC' }
    },
    '23ASC': {
        name: '23ASC',
        title: i18n => (i18n.__('Mileage Asc')),
        urlParam: { orderColumn: 'filter23', orderDirection: 'ASC' }
    },
    '23DESC': {
        name: '23DESC',
        title: i18n => (i18n.__('Mileage Desc')),
        urlParam: { orderColumn: 'filter23', orderDirection: 'DESC' }
    },
    '121ASC': {
        name: '121ASC',
        title: i18n => (i18n.__('Rooms Asc')),
        urlParam: { orderColumn: 'filter121', orderDirection: 'ASC' }
    },
    '121DESC': {
        name: '121DESC',
        title: i18n => (i18n.__('Rooms Desc')),
        urlParam: { orderColumn: 'filter121', orderDirection: 'DESC' }
    },
    '123ASC': {
        name: '123ASC',
        title: i18n => (i18n.__('Area Asc')),
        urlParam: { orderColumn: 'filter123', orderDirection: 'ASC' }
    },
    '123DESC': {
        name: '123DESC',
        title: i18n => (i18n.__('Area Desc')),
        urlParam: { orderColumn: 'filter123', orderDirection: 'DESC' }
    },
    '125ASC': {
        name: '123ASC',
        title: i18n => (i18n.__('Floor Asc')),
        urlParam: { orderColumn: 'filter125', orderDirection: 'ASC' }
    },
    '125DESC': {
        name: '123DESC',
        title: i18n => (i18n.__('Floor Desc')),
        urlParam: { orderColumn: 'filter125', orderDirection: 'DESC' }
    },
    '143ASC': {
        name: '143ASC',
        title: i18n => (i18n.__('Land area Asc')),
        urlParam: { orderColumn: 'filter143', orderDirection: 'ASC' }
    },
    '143DESC': {
        name: '143DESC',
        title: i18n => (i18n.__('Land area Desc')),
        urlParam: { orderColumn: 'filter143', orderDirection: 'DESC' }
    },
    viewsDayDESC: {
        name: 'viewsDayDESC',
        title: i18n => (i18n.__('The most viewed by day')),
        urlParam: { orderColumn: 'viewsDay', orderDirection: 'DESC' }
    },
    viewsWeekDESC: {
        name: 'viewsWeekDESC',
        title: i18n => (i18n.__('The most viewed by week')),
        urlParam: { orderColumn: 'viewsWeek', orderDirection: 'DESC' }
    },
    viewsMonthDESC: {
        name: 'viewsMonthDESC',
        title: i18n => (i18n.__('The most viewed by month')),
        urlParam: { orderColumn: 'viewsMonth', orderDirection: 'DESC' }
    }
};
