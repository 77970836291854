import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';


export default class extends PureComponent {
    handleClick = () => {
        const { handleDropup, category } = this.props;
        if (handleDropup) {
            handleDropup(category);
        }
    };

    render() {
        const { value, ml, link } = this.props;
        const { badgeFilters, badgeChat, badgeMyAds, badgeBookmarks, badgeDefault, badgeMyCompany, showNumber } = this.props;
        const displayValue = `${value < 100 || showNumber ? value : '+99'}`;
        const className = classNames('badge', {
            'ms-1': ml,
            'badge-filters cursor-pointer': badgeFilters,
            'badge-chat': badgeChat,
            'badge-myads': badgeMyAds || badgeMyCompany,
            'badge-bookmarks': badgeBookmarks,
            'badge-default': badgeDefault
        });
        if (value > 0) {
            if (link) {
                return (<Link to={link}><span className={className}>{displayValue}</span></Link>);
            }
            return (<span onClick={this.handleClick} className={className}>{displayValue}</span>);
        }
        return null;
    }
}
