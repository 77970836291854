import {
    GET_COMPANY_DATA,
    GET_COMPANY_CATEGORIES,
    DELETE_XML_FEED,
    GET_COMPANY_FEEDS,
    CLEAR_COMPANY_STATE,
    SET_COMPANY_HISTORY,
    CLEAR_COMPANY_FEEDS,
    GET_MY_COMPANY_LOTS_AMOUNT,
    ADD_COMPANY_LOT,
    GET_COMPANY_MEMBER,
    RESET_USER_STATE,
    REMOVE_COMPANY_LOT,
    GET_FEED_LOGS,
    GET_FEED_CATEGORIES,
    INCREMENT_COMPANY_LOT,
    COMPANY_PAGE_SELECTED_CATEGORY, COMPANY_PAGE_CURRENT_CATEGORY, COMPANY_PAGE_SHOW_FILTERS, GET_COMPANY_MEMBERS
} from 'ACTIONS';

import { checkNested } from 'MODULES/checkNested';

const INITIAL_STATE = {
    companyData: null,
    companiesById: {},
    companyCategories: false,
    companyCategoriesCount: null,
    companyLotsAmount: 0,
    memberData: {},
    feedLog: {},
    feedCategories: {},
    companyPage: {
        showFilters: false
    },
    companyMembers: false
};

export default function (state = INITIAL_STATE, action) {
    const { companiesById, memberData, companyCategories } = state;
    switch (action.type) {
        case SET_COMPANY_HISTORY:
            return action.payload;
        case GET_COMPANY_DATA:
            return {
                ...state,
                companyData: action.dispatchById ? state.companyData : action.payload,
                companiesById: {
                    ...state.companiesById,
                    [action.payload.id]: { ...checkNested(state.companiesById, action.payload.id, {}), ...action.payload }
                }
            };
        case GET_COMPANY_CATEGORIES:
            return { ...state, companyCategories: { ...checkNested(state, 'companyCategories', {}), [action.location]: action.payload.data }, companyCategoriesCount: action.payload.count };
        case GET_MY_COMPANY_LOTS_AMOUNT:
            return { ...state, companyLotsAmount: action.payload };
        case ADD_COMPANY_LOT:
            return { ...state, companyLotsAmount: state.companyLotsAmount + 1 };
        case REMOVE_COMPANY_LOT:
            return { ...state, companyLotsAmount: state.companyLotsAmount - 1 };
        case INCREMENT_COMPANY_LOT:
            return { ...state, companyLotsAmount: state.companyLotsAmount + action.amount };
        case GET_COMPANY_FEEDS:
            return {
                ...state,
                companyFeeds: action.payload
            };
        case GET_FEED_LOGS:
            return {
                ...state,
                feedLog: { ...state.feedLog, [action.id]: action.payload }
            };
        case GET_FEED_CATEGORIES:
            return {
                ...state,
                feedCategories: { ...state.feedCategories, [action.id]: action.payload }
            };
        case DELETE_XML_FEED:
            return {
                ...state,
                companyFeeds: {
                    ...state.companyFeeds,
                    [action.owner]: state.companyFeeds[action.owner].filter(i => i.id !== action.id)
                }
            };
        case CLEAR_COMPANY_FEEDS:
            return {
                ...state,
                companyFeeds: null
            };
        case GET_COMPANY_MEMBER:
            return {
                ...state,
                memberData: { ...state.memberData, [action.payload.id]: action.payload }
            };
        case CLEAR_COMPANY_STATE:
            return INITIAL_STATE;
        case COMPANY_PAGE_SELECTED_CATEGORY:
            return { ...state, companyPage: { ...state.companyPage, selectedCategory: action.payload } };
        case COMPANY_PAGE_CURRENT_CATEGORY:
            return { ...state, companyPage: { ...state.companyPage, currentCategory: action.payload } };
        case COMPANY_PAGE_SHOW_FILTERS:
            return { ...state, companyPage: { ...state.companyPage, showFilters: action.payload } };
        case GET_COMPANY_MEMBERS:
            return {
                ...state, companyMembers: action.payload.content
            };
        case RESET_USER_STATE:
            return { ...INITIAL_STATE, companiesById, memberData, companyCategories };
        default:
            return state;
    }
}
