import axios from 'axios';
import { API, SAVE_ROUTE_TO_CACHE } from 'ACTIONS';
import { apiAccess, apiError, apiStart, apiEnd } from 'ACTIONS/api';
import Storage from 'HOC/storage';
import { setAccessToken } from 'MODULES/api';

import omit from 'lodash/omit';
import { OAUTH_PARAMS, OAUTH_URL, ROUTE_TTL, STORAGE } from 'CONFIG';
import formURLEncoded from 'form-urlencoded';
import { buildMessagingApiUrl } from 'MODULES/buildMessagingApiUrl';
import { checkNested } from 'MODULES/checkNested';
import { buildApiUrl } from 'MODULES/buildApiUrl';
import { showErrorMsg } from 'ACTIONS/ui/showErrorMsg';

const process = { env: __DOTENV__ || window.env };

const apiMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (!action) {
        return;
    }

    if (typeof action === 'function') {
        return action(dispatch, getState);
    }

    if (!action.type) {
        return false;
    }

    next(action);

    if (action.type !== API) return;


    // routerMiddleware(history)

    const {
        url,
        method,
        data,
        host,
        onSuccess,
        onFailure,
        formError,
        label,
        notificationLabel,
        chained,
        reject,
        resolve,
        msg,
        notificationFunction,
        headers,
        returnParams,
        apiPrefix,
        withCredentials,
        successOnError,
        controller,
        onUploadProgress,
        auth
    } = action.payload;

    // if (getState().routeCache.success[url]) {
    //     return;
    // }

    const dataOrParams = ['GET', 'DELETE'].includes(method)
        ? 'params' : 'data';
    // axios default configs


    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || '';
    axios.defaults.headers.common['Content-Type'] = 'application/json';


    axios.interceptors.response.use(
        response => response,
        error => new Promise((resolvePromise, rejectPromise) => {
            if (error.response && error.response.status === 401 && !checkNested(Storage.get('tokensLong', true), 'refresh')) {
                setAccessToken({});
                Storage.set(STORAGE.login, 0);
                window.location.reload();
            }
            if (error.response && error.response.status === 401 && checkNested(Storage.get('tokensLong', true), 'refresh')) {
                const oldToken = error.config.headers.Authorization.replace('Bearer ', '');
                if (!checkNested(window, ['failedTokens', oldToken])) {
                    window.failedTokens = { ...checkNested(window, 'failedTokens', {}), [oldToken]: true };
                    const refreshToken = checkNested(Storage.get('tokensLong', true), 'refresh');
                    axios.post(
                        OAUTH_URL,
                        formURLEncoded({ ...OAUTH_PARAMS({ grant_type: 'refresh_token' }), ...{ refresh_token: refreshToken } }),
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    ).then((tokens) => {
                        setAccessToken(tokens.data);
                    }).catch(() => {
                        setAccessToken({});
                        Storage.set(STORAGE.login, 0);
                        window.location.reload();
                    });
                }
                const newInterval = setInterval(() => {
                    const cookieToken = checkNested(Storage.get('tokensShort', true), 'access');
                    if (cookieToken !== oldToken) {
                        clearInterval(newInterval);
                        return axios.request({ ...error.config,
                            headers: {
                                ...error.config.headers,
                                Authorization: `Bearer ${cookieToken}`
                            } }).then(resolvePromise).catch(() => {});
                    }
                }, 100);
            } else {
                return rejectPromise(error);
            }
        })
    );

    if (label) {
        dispatch(apiStart(label));
    }
    let authToken = checkNested(Storage.get('tokensShort', true), 'access') || checkNested(headers, 'Authorization');
    if (authToken && withCredentials && auth) {
        authToken = { Authorization: `Bearer ${authToken}` };
    } else {
        authToken = {};
    }
    const nonAuthHeaders = omit(headers, ['Authorization', 'refresh']);
    const requestUrl = msg ? buildMessagingApiUrl(url, '', getState) : buildApiUrl(url, '', getState, host, apiPrefix);

    axios
        .request({
            url: requestUrl,
            method,
            signal: controller && controller.signal,
            onUploadProgress,
            headers: { ...nonAuthHeaders, ...authToken },
            [dataOrParams]: data
        })
        .then((res) => {
            const { data, headers } = res || {};
            if (data && (!data.error || successOnError)) {
                resolve(data);
                dispatch({
                    type: SAVE_ROUTE_TO_CACHE,
                    payload: { route: url, ttl: new Date().getTime() + ROUTE_TTL }
                });
                dispatch(onSuccess(data.data ? { content: data } : data, headers, returnParams, res));
            }
            if (!data) {
                dispatch(onSuccess({}, headers));
            }
            if (data && data.notification) {
                dispatch(notificationFunction(notificationLabel || label, data));
            }
            if (data && data.error && !successOnError) {
                dispatch(showErrorMsg(notificationLabel || label, checkNested(data, 'error', [])));
                dispatch(onFailure(data));
                if (formError) {
                    reject(checkNested(data, 'error', []));
                }
            }
        })

        .catch((error) => {
            dispatch(showErrorMsg(notificationLabel || label, checkNested(error.response, 'data.error', [])));
            dispatch(apiError(error));
            if (formError) {
                reject(error);
                // reject(new SubmissionError({ _error: checkNested(error, 'response.data.error', []) }));
            } else {
                resolve();
            }

            dispatch(onFailure(checkNested(error, 'response.data'), checkNested(error.response, 'status')));

            if (error.response && error.response.status === 403) {
                dispatch(apiAccess(window.location.pathname));
            }
        })
        .finally(() => {
            if (label) {
                dispatch(apiEnd(label, chained));
            }
        });
};

export default apiMiddleware;

