import React from 'react';

import { PP_URLS } from 'CONFIG/PP_URLS';


const IconPoweredBy = ({ className }) => (
    <svg className={className} height="26.373" width="56.112" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0 -2.627)" fill="none">
            <rect rx="4" height="19" width="56" y="10" fill="#fc4a1a" />
            <path className="txt" d="M1.88 2.929h2.36q.571 0 .985.237.422.238.633.643.22.404.22.888t-.22.89-.642.642q-.414.237-.977.237H2.611v2.332h-.73v-5.87zm3.442 1.768q0-.492-.326-.8-.326-.317-.845-.317h-1.54v2.235h1.54q.52 0 .845-.308.326-.317.326-.81zm1.221 1.171q0-.863.36-1.549.37-.695 1.03-1.091.669-.396 1.523-.396t1.513.396T12 4.319q.37.686.37 1.549T12 7.425q-.37.687-1.03 1.083t-1.513.396-1.523-.396q-.66-.396-1.03-1.083-.36-.695-.36-1.557zm5.069 0q0-.687-.273-1.223-.264-.546-.757-.854-.484-.308-1.126-.308-.652 0-1.144.308-.484.308-.748.854Q7.3 5.18 7.3 5.868q0 .677.264 1.223t.756.854 1.136.308 1.126-.308q.493-.308.757-.854.273-.546.273-1.223zm4.672-1.804L14.99 8.798h-.792l-1.672-5.87h.81l1.302 4.893 1.346-4.892h.608l1.346 4.892 1.294-4.892h.81l-1.673 5.87h-.792l-1.293-4.735zm4.318-1.135h3.845v.65h-3.115v1.893h3.054v.651h-3.054v2.024h3.115v.651h-3.845v-5.87zm6.662 3.537h-1.17v2.332h-.73v-5.87h2.358q.537 0 .95.22.423.212.651.617.238.404.238.932 0 .51-.211.89-.203.369-.555.58-.343.202-.756.238l1.584 2.393h-.863l-1.496-2.332zm1.54-1.769q0-.492-.325-.8-.326-.317-.845-.317h-1.54v2.244h1.54q.519 0 .845-.317t.325-.81zm1.648-1.768h3.845v.65h-3.115v1.893h3.054v.651h-3.054v2.024h3.115v.651h-3.845v-5.87zm4.762 0h2.007q.889 0 1.575.387.686.378 1.056 1.056.378.669.378 1.496 0 .836-.378 1.505-.37.66-1.056 1.047-.686.378-1.575.378h-2.007v-5.87zm2.007 5.218q.695 0 1.205-.3.51-.307.775-.826.273-.52.273-1.153 0-.643-.264-1.162t-.775-.818q-.51-.308-1.214-.308h-1.276v4.567h1.276z" style={{ fill: '#000' }} fill="#fff" />
            <path d="M7.708 14.182q.707 0 .707.694t-.707.693q-.68 0-.68-.693t.68-.694zm0 9.136q-.68 0-.68-.694v-5.811q0-.694.68-.694.707 0 .707.694v5.811q0 .694-.707.694zm6.504-6.088q-1.675 0-3.037.97v4.423q0 .694-.706.694-.681 0-.681-.694V17.86q0-.314.262-.537 1.82-1.479 4.162-1.479t4.162 1.48q.262.222.262.536v4.764q0 .694-.694.694-.68 0-.68-.694V18.2q-1.388-.969-3.05-.969zm10.226 4.988q1.675 0 3.05-.982V18.2q-1.388-.969-3.05-.969-1.675 0-3.037.969v3.036q1.348.982 3.036.982zm-4.424-7.343q0-.694.68-.694.707 0 .707.694v1.715q1.414-.746 3.037-.746 2.343 0 4.162 1.479.262.222.262.536v3.718q0 .314-.262.536-1.793 1.48-4.162 1.48t-4.162-1.48q-.262-.222-.262-.536v-6.702zm14.649 7.343q1.675 0 3.05-.982V18.2q-1.388-.969-3.05-.969-1.675 0-3.037.969v3.036q1.347.982 3.036.982zm4.424-.641q0 .314-.262.536-1.793 1.48-4.162 1.48-2.369 0-4.162-1.48-.262-.222-.262-.536v-3.718q0-.314.262-.536 1.82-1.48 4.162-1.48t4.162 1.48q.262.222.262.536v3.718zm.946 1.021q0-.262.314-.524l3.024-2.343-3.024-2.369q-.314-.248-.314-.51 0-.117.066-.248.065-.144.157-.25.104-.117.235-.182.131-.079.249-.079.21 0 .458.183l3.311 2.579 3.299-2.579q.248-.183.458-.183.118 0 .248.079.131.065.223.183.105.105.17.249.066.13.066.248 0 .262-.315.51l-3.023 2.37 3.023 2.343q.315.262.315.523 0 .131-.066.275-.052.131-.157.236-.092.104-.222.183-.131.065-.262.065-.21 0-.458-.183l-3.299-2.578-3.311 2.578q-.249.183-.458.183-.118 0-.249-.065-.13-.079-.235-.183-.092-.118-.157-.249-.066-.143-.066-.26z" fill="#f9f9f9" />
            <path className="txt" d="M44.584 2.929h2.613q.757 0 1.197.413.449.414.449 1.083 0 .528-.3.897-.299.361-.73.449.308.044.572.255t.414.528q.158.317.158.66 0 .713-.457 1.153-.45.431-1.241.431h-2.675v-5.87zm2.49 2.543q.484 0 .748-.264t.264-.678-.273-.677q-.264-.273-.739-.273h-1.76v1.892h1.76zm.035 2.675q.51 0 .801-.273t.29-.739q0-.431-.29-.722-.282-.29-.8-.29h-1.796v2.024h1.795zm4.201-1.83-2.314-3.388h.854l1.822 2.728 1.821-2.728h.854L52.04 6.317v2.481h-.73V6.317z" style={{ fill: '#000' }} fill="#fff" />
        </g>
    </svg>
);

const PoweredBy = ({ className }) => (
    <a rel="noopener" className={className} href={PP_URLS.INBOX} target="_blank" aria-label={PP_URLS.INBOX}>
        <IconPoweredBy className="powered-by-inbox" />
    </a>
);

PoweredBy.defaultProps = {
    className: ''
};

export default PoweredBy;

