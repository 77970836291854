import {
    CATEGORY_REGIONS, CATEGORY_SUB_REGIONS, DELETE_PP,
    GET_CATEGORY_FILTERS, GET_PPS_ARRAY,
    SET_FILTER_VALUES,
    SET_LOT_LIST,
    SET_LOT_LIST_COUNT,
    SET_QUERY_STRING, SET_QUERY_STRING_WITHOUT_COORDINATES
} from 'ACTIONS';
import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { checkNested } from 'MODULES/checkNested';
import { PP_TYPE } from 'MODULES/PP_TYPE';

const INITIAL_STATE = {
    filterByCategory: {},
    filterValues: {},
    queryString: {},
    queryStringWithoutCoordinates: {},
    sortBy: {},
    ppCount: {},
    ppList: {},
    fetchingList: {},
    regions: {},
    ppsByCoordinates: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CATEGORY_REGIONS:
        case CATEGORY_SUB_REGIONS:
            return { ...state,
                regionsById: { ...state.regions.regionsById, ...keyBy(action.data, 'id') },
                regionsBySlug: { ...state.regions.regionsBySlug, ...keyBy(action.data, 'slug') },
                regions: {
                    ...state.regions,
                    [action.child ? `${action.categorySlug}/${action.child}` : action.categorySlug]: {
                        ...state.regions[action.categorySlug],
                        parent: !action.child ? action.payload.data : false,
                        child: action.child ? map(action.payload.data, c => ({
                            ...c,
                            parent: checkNested(action.payload.parent, 'id') || action.payload.parent
                        })) : false,
                        childByLetters: action.child ? action.payload.groupByLetters : false,
                        parentsByLetters: action.payload.groupByLetters
                    }
                }
            };
        case SET_QUERY_STRING:
            return { ...state,
                queryString: {
                    [action.ppType]: {
                        ...checkNested(state.queryString, action.ppType, {}),
                        [action.location]: action.payload
                    }
                } };
        case GET_PPS_ARRAY:
            return { ...state,
                ppsByCoordinates: { ...state.ppsByCoordinates,
                    [action.coordinates]: action.payload } };
        case SET_QUERY_STRING_WITHOUT_COORDINATES:
            return { ...state,
                queryStringWithoutCoordinates: {
                    [action.ppType]: {
                        ...checkNested(state.queryStringWithoutCoordinates, action.ppType, {}),
                        [action.location]: action.payload
                    }
                } };
        case GET_CATEGORY_FILTERS:
            return { ...state, filterByCategory: action.payload };
        case SET_FILTER_VALUES:
            return { ...state,
                filterValues: {
                    ...state.filterValues,
                    [action.ppType]: {
                        ...omit(checkNested(state.filterValues, action.ppType, {}), action.payload.street === 0 ? 'street' : ''),
                        [action.location]: action.payload
                    }
                } };
        case SET_LOT_LIST_COUNT:
            return { ...state,
                ppCount: {
                    ...state.ppCount,
                    [action.ppType]: {
                        ...checkNested(state.ppCount, action.ppType, {}),
                        [action.location]: {
                            ...checkNested(state.ppCount, [action.ppType, action.location], {}),
                            [action.queryString]: action.count
                        }
                    }
                } };
        case DELETE_PP:
            return {
                ...state,
                ppList: {
                    ...state.ppList,
                    [action.lotsPath.ppType]: {
                        ...checkNested(state.ppList, action.lotsPath.ppType, {}),
                        [action.lotsPath.pathname]: {
                            ...checkNested(state.ppList, [action.lotsPath.ppType, action.lotsPath.pathname], {}),
                            [action.lotsPath.queryString]: filter(checkNested(state.ppList, [action.lotsPath.ppType, action.lotsPath.pathname, action.lotsPath.queryString], []), action.payload)
                        }
                    }
                }
            };
        case SET_LOT_LIST:
            if (action.ppType === PP_TYPE.mapList) {
                return { ...state,
                    ppList: {
                        ...state.ppList,
                        [action.ppType]: {
                            ...checkNested(state.ppList, action.ppType, {}),
                            [action.location]: {
                                ...checkNested(state.ppList, [action.ppType, action.location], {}),
                                [action.queryString]: action.payload
                            }
                        }
                    } };
            }
            return { ...state,
                ppList: {
                    ...state.ppList,
                    [action.ppType]: {
                        ...checkNested(state.ppList, action.ppType, {}),
                        [action.location]: {
                            ...checkNested(state.ppList, [action.ppType, action.location], {}),
                            [action.queryString]: action.ppType === PP_TYPE.infinite && checkNested(state.ppList, [action.ppType, action.location, action.queryString]) ? [
                                ...state.ppList[action.ppType][action.location][action.queryString],
                                ...action.payload
                            ] : action.payload
                        }
                    }
                } };
        default:
            return state;
    }
}
