import { language } from 'MODULES/language';
import { checkNested } from 'MODULES/checkNested';
import { API_URLS } from 'CONFIG/API_URLS';
import { removeLastSlash } from 'MODULES/api';
import { apiAction } from 'ACTIONS/api';
import { CATEGORIES } from 'MODULES/CATEGORIES';
import { isMobile } from 'react-device-detect';
import { stringExistsIn } from 'MODULES/validations';
import Storage from 'HOC/storage';
import { DEFAULT_LANG } from 'CONFIG';
import { matchPath } from 'react-router-dom';
import { FLUSH_CATEGORY_DATA, GET_CATEGORY_DATA, GET_HOME_CATEGORIES_AD_COUNT, NOT_FOUND } from 'ACTIONS';
import { getParent } from 'MODULES/getParent';
import last from 'lodash/last';
import size from 'lodash/size';

export const getCategoryData = (category, isSlug = false, subcategories = false, lng, flush = false, history, location, prefix = '', onSuccess) => (dispatch, getState) => {
    const lang = lng || language(getState);
    let requestUrl;
    let disableChildrens = false;
    const state = getState();
    if (category && checkNested(state.categories.categories, ['currentCategory', 'slug', lng]) === category) return;
    if (category) {
        if (isSlug) {
            requestUrl = subcategories ? API_URLS.SLUG_SUBCATEGORIES(removeLastSlash(category)) : API_URLS.SLUG_CATEGORIES(removeLastSlash(category));
        } else {
            requestUrl = subcategories ? API_URLS.SUBCATEGORIES(category) : API_URLS.CATEGORIES_BY_ID(category);
        }
    } else {
        requestUrl = API_URLS.CATEGORIES;
        disableChildrens = true;
    }
    return dispatch(apiAction({
        url: requestUrl,
        auth: false,
        onSuccess: (data) => {
            let categoryData = checkNested(data.content, 'data.0', {});
            if (data.content.children) {
                const { length } = data.content.children;
                const groups = {};

                for (let i = 0; i < length; i++) {
                    const item = data.content.children[i];
                    const firstChar = item.name && item.name[lang].charAt(0);
                    if (item.id === CATEGORIES.winter_beater && isMobile) {
                        groups.top = [item];
                    } else if (i === length - 1 && stringExistsIn('/cits', item.slug.lv)) {
                        groups.other = groups.other || [];
                        groups.other.push(item);
                    } else {
                        groups[firstChar] = groups[firstChar] || [];
                        groups[firstChar].push(item);
                    }
                }

                const sortedGroups = Object.keys(groups).sort((a, b) => a.localeCompare(b));
                const newGroups = {};

                sortedGroups.forEach((key) => {
                    newGroups[key] = groups[key];
                });

                categoryData = { ...data.content, childrenGroupedByLetter: newGroups };
                if (onSuccess) {
                    onSuccess();
                }
            }
            try {
                if (history && data.content.category) {
                    const state = getState();
                    const langFromCookie = Storage.get('language') || DEFAULT_LANG;
                    const histLink = history.location.pathname;
                    const catLink = `/${langFromCookie}/${prefix}${data.content.category.slug[langFromCookie]}`;
                    if (langFromCookie && checkNested(categoryData, ['category', 'slug', langFromCookie])) {
                        if (catLink !== histLink && histLink.indexOf(catLink) === -1) {
                            history.push({
                                ...location,
                                pathname: `/${langFromCookie}/${prefix}${data.content.category.slug[langFromCookie]}`
                            });
                        }
                    } else if (state.general.selectedLang) {
                        const match = matchPath(history.location.pathname, {
                            path: '/:lang/**/!:ppid',
                            exact: false,
                            strict: false
                        });
                        if (state.general.selectedLang !== match.params.lang || categoryData.slug[langFromCookie] !== match.params[0]) {
                            if (match.url !== `/${state.general.selectedLang}/${categoryData.slug[state.general.selectedLang]}/!${match.params.ppid}`) {
                                history.push({
                                    ...location,
                                    pathname: `/${state.general.selectedLang}/${categoryData.slug[state.general.selectedLang]}/!${match.params.ppid}`
                                });
                            }
                        }
                    }
                }
                const lang = language(getState);
                if (flush) dispatch({ type: FLUSH_CATEGORY_DATA });
                let selectedCategory = {};
                if (size(categoryData.parents) > 2) {
                    size(categoryData.parents) === 5
                        ? selectedCategory = {
                            1: checkNested(categoryData.parents, '2.id'),
                            2: checkNested(categoryData.parents, '3.id'),
                            3: categoryData.category.id
                        }
                        : selectedCategory = {
                            1: checkNested(categoryData.parents, '2.id'),
                            2: checkNested(categoryData.parents, '3.id')
                        };
                }

                dispatch({
                    type: GET_CATEGORY_DATA,
                    disableChildrens,
                    payload: subcategories || (!subcategories && !category) ? { ...categoryData, selectedCategory, lang } : {
                        category: categoryData,
                        parents: getParent(categoryData, true),
                        selectedCategory,
                        lang
                    }
                });

                const state = getState();
                const lastHomeCat = last(state.home.homeCategoriesLots[lang]);
                if (categoryData && !checkNested(lastHomeCat, 'adCount')) {
                    dispatch({
                        type: GET_HOME_CATEGORIES_AD_COUNT,
                        payload: categoryData,
                        lang: language(getState)
                    });
                }
            } catch (e) {
                return e;
            }
        },
        onFailure: () => ({
            type: NOT_FOUND,
            errorType: GET_CATEGORY_DATA,
            payload: true
        }),
        label: GET_CATEGORY_DATA
    }));
};
